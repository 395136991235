@import 'styles/includes.scss';

.EventSubmissionFormV2 {
    $root: &;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;

    &__Title,
    &__StepHeader,
    &__ContactUsText {
        color: $colorBlack750;
    }

    &__Preamble,
    &__StepText,
    §__Meta,
    &__MetaInfo,
    &__ContactUsTitle,
    &__ProgressLabel {
        color: $colorBlack800;
    }

    &__Container {
        display: flex;
        flex-direction: column;
        position: relative;
        margin-top: 2.6rem;

        @include media(l) {
            flex-direction: row;
            justify-content: space-between;
            margin-top: 6.4rem;
        }
    }

    &__ProgressBarBackground {
        height: 8px;
        background: $colorGray200;
        width: 100vw;
        position: relative;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: -100%;
            width: 100%;
            height: 8px;
            background: $colorPink;
        }

    }

    &__ProgressLine {
        height: 8px;
        background: $colorPink;
        width: 0;
        transition: width .6s ease-in-out;
        top: -8px;
        position: relative;
    }

    &__ProgressLabel {
        @extend %labels;
        position: relative;
        flex: 1;
        text-align: center;
        padding-top: 1rem;
        scale: 1;
        transition: all .6s ease-in-out;

        &--Active {
            scale: 1.2;

            @include media(l) {
                scale: 1.4;
            }
        }
    }

    &__ProgressBar {
        display: none;
        position: relative;

        @include media(m) {
            display: flex;
            justify-content: space-between;
            width: 100%;
            cursor: default;
        }
    }

    &__BottomProgressBar {
        height: .8rem;
        margin-top: 3.2rem;

        @include media(m){
            margin-top: 9.6rem;
        }
    }

    &__FormContainer {
        width: 100%;
        max-width: 85.6rem;
    }

    &__StepContainer {
        transition: opacity .5s ease-in-out;
        opacity: 0;

        &--Show {
            opacity: 1;
        }

        &--Hide {
            opacity: 0;
        }
    }

    &__Title {
        @extend %h1;
        margin-bottom: 2.4rem;

        @include media(l) {
            margin-bottom: 3.2rem;
        }
    }

    &__Preamble {
        @extend %rich-text;
        margin-bottom: 4.2rem;
    }

    &__Step {
        display: block;
        @extend %labels;
        margin-bottom: 1.2rem;

        @include media(m){
            margin-bottom: 1.6rem;
        }
    }

    &__StepHeader {
        @extend %h2;
        margin-bottom: .8rem;
    }

    &__StepText {
        margin-bottom: 3.2rem;

        &--RichText {
            @extend %rich-text;
        }
    }

    &__ImageWrapper {
        max-width: 30rem;
        flex: 1;

        #{$root}__Summation & {
            max-width: 28.8rem;
            margin-bottom: 2.4rem;
        }

        #{$root}__Confirmation & {
            margin-right: 2.4rem;
        }
    }

    &__Image {
        width: 100%;
    }

    &__TextWrapper {
        flex: 1;
    }

    &__ButtonsWrapper {
        display: flex;
        justify-content: space-between;
        margin-top: 1.6rem;

        @include media(l){
            margin-top: 3.2rem;
        }

        &--FlexEnd {
            justify-content: flex-end;
        }
    }

    &__SubmissionError {
        margin: 2.4rem 0;
        padding: 1.2rem;
        border: 2px solid rgb(214, 54, 0);
        color: $colorBlack800;

    }

    &__ErrorLink {
        color: $colorBlack800;

        &:hover {
            color: $colorPink;
        }
    }

    &__ErrorMessage {
        margin-top: 1rem;
        display: block;
        color: rgb(214, 54, 0);
    }

    &__Oops {
        display: block;
        color: rgb(214, 54, 0);
        font-weight: 700;
        margin-bottom: 1.2rem;
    }

    &__RecaptchaDisclaimer {
        display: block;
        font-size: 1.4rem;
        margin-top: 4rem;

        a {
            color: $colorBlack750;

            &:hover {
                color: $colorPink;
            }
        }
    }

    &__Prev {
        font-family: $fontBrand;
        background-color: transparent;
        font-size: 1.8rem;
        line-height: 21.6px;
        color: $colorBlack800;
        padding-left: 3.6rem;
        position: absolute;

        border: none;
        margin-bottom: 4.2rem;

        @include media(m){
            top: calc((42px + 26px) * -1);
        }

        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            height: 2.4rem;
            width: 2.4rem;
            background-image: url('#{$assetPath}img/arrow-left.svg');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            transition: transform .3s ease-in-out;
        }

        &:hover {
            &::before {
                transform: translate(-4px, -50%);
            }
        }
    }

    &__MetaTitle,
    &__EventTitle {
        @extend %h4;
        line-height: 3.2rem;
        letter-spacing: .4px;
        font-weight: 700;
    }

    &__EventTitle {
        margin-bottom: .4rem;
    }

    &__MetaTitle {
        margin-bottom: 1.6rem;

        &--Info {
            margin-bottom: .8rem;
        }
    }

    &__EventLabel {
        text-transform: uppercase;
        font-size: 1.2rem;
        letter-spacing: 2.6px;
        margin-bottom: 2rem;

        @include media(m) {
            font-size: 1.4rem;
        }
    }

    &__Meta {
        padding-left: 4rem;
        position: relative;
        margin-bottom: 1.2rem;

        &:last-child {
            margin-bottom: 0;
        }

        &::after {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                width: 2.4rem;
                height: 2.4rem;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
        }

        &--Address {
            &::after {
                background-image: url('/assets/img/place.svg');
                top: 6px;
                transform: translateY(0);
            }
        }

        &--ExternalWebsite {
            color: $colorPink;
            &::after {
                background-image: url('/assets/img/icon-external-link-black.svg');
            }
        }

        &--Email {
            &::after {
                background-image: url('/assets/img/icon-email-black.svg');
            }
        }
    }

    &__MetaInfo {
        margin-bottom: 1.6rem;
    }

    &__Date {
        display: flex;
        justify-content: space-between;
    }

    &__EventDescription {
        @extend %body-2;
        margin-bottom: 2.4rem;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__Section {
        padding-bottom: 2.4rem;
        border-bottom: 1px solid $colorGray200;
        margin-bottom: 2.4rem;

        &--Flex {
            display: flex;
        }
    }

    &__Row {
        display: block;
    }

    &__Column {
        flex: 1;
    }

    &__Confirmation {
        display: flex;
        flex-direction: column;
        margin-bottom: 3.2rem;

        @include media(m){
            flex-direction: row;
        }
    }

    &__ContactUs {
        background: $colorOrange50;
        margin-top: 6.4rem;
        padding: 3.2rem 2.4rem;
        align-self: flex-start;
        width: 100%;

        @include media(l) {
            max-width: 40.4rem;
            margin-top: 3.2rem;
            margin-left: 10rem;
        }
    }

    &__ContactUsTitle {
        @extend %h5;
        margin-bottom: .8rem;
    }

    &__ContactUsText {
        @extend %rich-text;

        & > *:last-child {
            margin-bottom: 0;
        }
    }
}
