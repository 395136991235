@import 'styles/includes.scss';

.Dropdown {
    padding-bottom: $spacingXl;
}

.SelectControl {
    min-height: 47px !important;
    background-color: $colorWhiteBackground;
    cursor: pointer !important;
    border-radius: 10px !important;
    color: $colorGray700 !important;
    border: 1px solid $colorBlack800 !important;
    font-size: 1.8rem !important;
    transition: none !important;
    padding: 0 6px !important;

    &--IsFocused {
        box-shadow: none !important;
        border: 1px solid $colorBlack800 !important;
    }

    &:hover {
        border: 1px solid $colorBlack800 !important;
    }

    &--IsMenuOpened {
        padding-left: 2.4rem !important;
        &::before {
            content: '';
            display: inline-block;
            position: absolute;
            left: 0.8rem;
            top: 50%;
            transform: translateY(-50%);
            background: url(#{$assetPath}img/search.svg);
            width: 19px;
            height: 26px;
            background-size: 83%;
            background-repeat: no-repeat;
            background-position-y: 5px;
            background-position-x: 3px;
        }
    }
}

.Menu {
    overflow: hidden !important;
}

.MenuList {
    padding: 0 !important;
}

.Option {
    background-color: white !important;
    cursor: pointer !important;
    transition: background-color $duration $ease !important;

    &:not(.Option--IsSelected) {
        &:hover {
            background-color: $colorPink300 !important;
        }
    }

    &--IsFocused {
        background-color: $colorPink300 !important;
    }

    &--IsSelected {
        background-color: $colorPink !important;
    }

}

.DropdownIndicator {
    color: $colorBlack800 !important;
    transition: transform $transition !important;

    .SelectControl--IsMenuOpened & {
        transform: rotate(180deg) !important;
    }
}

.IndicatorSeparator {
    background-color: $colorGray500 !important;
}

// .SelectInput {
//     position: relative !important;
//     padding-left: 1.6rem;
// }

// .Placeholder {
//     padding-left: 1.6rem;
// }
