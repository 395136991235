@use "sass:math";
@import 'styles/includes.scss';

.LabeledIcon {
    &__Link {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-content: center;
        text-decoration: none;
        background-color: transparent;
        border: none;
        position: relative;

        &:hover,
        &:focus {
            svg {
                color: $colorPink;
            }
        }

        &--IsVertical {
            // margin-right: $spacingXl;

            // @include media(l) {
            //     margin-right: $spacingM * 3;
            // }
        }

        &--IsHorizontal {
            flex-direction: row;
            align-items: center;
            height: 100%;
        }
    }

    &__Anchor {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;

        &:hover + * svg,
        &:focus + * svg {
            color: $colorPink;
        }
    }

    &__LinkLabel {
        white-space: nowrap;
        font-family: $fontBrand;
        font-size: 1rem;
        line-height: 1.55rem;
        margin-top: 0.2rem;
        display: flex;

        .LabeledIcon__Link--IsVertical > & {
            display: inline-block;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: normal;
            color: $colorBlack800;
            text-align: center;
            margin-top: 0.2rem;
            font-size: 1.4rem;

            @include media(m) {
                font-size: 1.2rem;
            }

            @include media(l) {
                line-height: 1.65;
                font-size: 1.6rem;
            }
        }

        .LabeledIcon__Link--IsHorizontal > & {
            margin-top: 0;
            margin-right: $spacingS;
            display: inline-block;
            color: $colorBlack750;
            font-size: 1.2rem;
            line-height: math.div(26.4, 16);

            @include media(l) {
                font-size: 1.2rem;
                line-height: math.div(28.8, 18);
            }

            &:hover {
                color: $colorPink;
            }
        }
    }

    &__LinkIcon {
        // padding-top: -1rem;
    }

    // Adjustments for handling icon placement in svg
    .Icon--Search {
        margin-left: -0.2rem;
    }

    .Icon--Bookmark {
        margin-left: -0.4rem;
    }
}
